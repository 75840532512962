<template>
  <el-result icon="warning" title="403" sub-title="抱歉，您暂时没有权限访问...">
    <template #extra>
      <el-button type="primary" @click="toHome">
        返回首页
      </el-button>
    </template>
  </el-result>
</template>

<script>
export default {
  name: 'Exception403',
  methods: {
    toHome () {
      this.$router.push({ path: '/home' })
    }
  }
}
</script>
